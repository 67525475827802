export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form0: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Dokter Bedah'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Dokter Anestesi'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Perawat Anestesi'
    },
    p4: {
      label: 'Preme obat',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 3
    },
    p5: {
      widget: 'wdatenormal',
      data: null,
      col: 2,
      label: 'Tanggal Operasi'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Jam Operasi'
    }
  },
  form00: {
    p1: {
      label: 'Obat',
      widget: 'wcheckbox',
      data: [
        { text: '1', value: null, field: 'auto' },
        { text: '2', value: null, field: 'auto' },
        { text: '3', value: null, field: 'auto' },
        { text: '4', value: null, field: 'auto' }
      ],
      col: 6
    }
  },
  form6: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'O2',
      property: { attrs: { suffix: 'ltr/mnt', type: 'number' } }
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'N2O',
      property: { attrs: { suffix: 'ltr/mnt', type: 'number' } }
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Air',
      property: { attrs: { suffix: 'ltr/mnt', type: 'number' } }
    },
    p4: {
      label: 'Alat',
      widget: 'wradio',
      data: [
        { text: 'Nasal', value: 'Nasal' },
        { text: 'Masker', value: 'Masker' }
      ],
      col: 3
    },
    l1: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'ALAT ANESTESI'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Air',
      property: { attrs: { prefix: 'Vt', suffix: 'cc', type: 'number' } }
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Air',
      property: { attrs: { prefix: 'F', suffix: '/mnt', type: 'number' } }
    },
    p7: {
      label: 'TEHNIK ANESTESI',
      widget: 'wradio',
      data: [
        { text: 'Closed', value: 'Closed' },
        { text: 'Semi Closed', value: 'Semi Closed' },
        { text: 'Semi Open', value: 'Semi Open' }
      ],
      col: 3
    },
    p8: {
      label: 'PERNAFASAN',
      widget: 'wradio',
      data: [
        { text: 'Spontan', value: 'Spontan' },
        { text: 'Kontrol', value: 'Kontrol' },
        { text: 'Assisted', value: 'Assisted' }
      ],
      col: 3
    },
    p9: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'ETT No'
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'LMA No'
    },
    p11: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Trakeostomy No'
    },
    p12: {
      label: 'ANESTESI REGIONAL',
      widget: 'wradio',
      data: [
        { text: 'SAB', value: 'SAB' },
        { text: 'Epidural', value: 'Epidural' },
        { text: 'Blok Saraf Tepi', value: 'Blok Saraf Tepi' }
      ],
      col: 3
    }
  },
  form7: {
    p1: {
      label: 'MASALAH/PENYULIT',
      widget: 'wradio',
      data: [
        { text: 'Gangguan Nafas', value: 'Gangguan Nafas' },
        { text: 'Aritmia', value: 'Aritmia' },
        { text: 'Hipotensi', value: 'Hipotensi' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ],
      col: 6
    }
  },
  form8: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'Keluar OK Jam'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Tensi',
      property: { attrs: { suffix: 'mmHg', type: 'number' } }
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Nadi',
      property: { attrs: { suffix: 'x/mnt', type: 'number' } }
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Kesadaran'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Nafas',
      property: { attrs: { suffix: 'x/mnt', type: 'number' } }
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Sp02',
      property: { attrs: { suffix: '%', type: 'number' } }
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Lama Anastesi (Jam)',
      property: { attrs: { type: 'number' } }
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Lama Anastesi (Menit)',
      property: { attrs: { type: 'number' } }
    }
  },
  form9: {
    p1: {
      label: 'Pasien Pindah',
      widget: 'wradio',
      data: [
        { text: 'Recovery Room', value: 'Recovery Room' },
        { text: 'ICU / HCU', value: 'ICU / HCU' },
        { text: 'lain lain...', value: null, field: 'auto' }
      ],
      col: 12
    }
  },
  form10: {
    l1: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'Balance Cairan'
    },
    l2: {
      widget: 'wlabelkiri',
      data: [],
      col: 6,
      label: 'Cairan Masuk'
    },
    l3: {
      widget: 'wlabelkiri',
      data: [],
      col: 6,
      label: 'Cairan Keluar'
    },
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Kristaloid',
      property: { attrs: { type: 'number' } }
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Urine',
      property: { attrs: { type: 'number' } }
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Koloid',
      property: { attrs: { type: 'number' } }
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Darah',
      property: { attrs: { type: 'number' } }
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Darah',
      property: { attrs: { type: 'number' } }
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'NGT',
      property: { attrs: { type: 'number' } }
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Lain-lain',
      property: { attrs: { type: 'number' } }
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Lain-lain',
      property: { attrs: { type: 'number' } }
    }
  },
  form11: {
    // p1: {
    //   widget: 'wtext',
    //   data: null,
    //   col: 6,
    //   label: 'Total',
    //   property: { attrs: { readonly: true } }
    // },
    // p2: {
    //   widget: 'wtext',
    //   data: null,
    //   col: 6,
    //   label: 'Total',
    //   property: { attrs: { readonly: true } }
    // },
    p3: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'Defisit / Ekses',
      property: { attrs: { suffix: 'cc', type: 'number' } }
    }
  },
  form16: {
    l1: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'ALAT : '
    },
    p1: {
      label: 'ANESTESI REGIONAL',
      widget: 'wcheckbox',
      data: [
        { text: 'SAB', value: 'SAB' },
        { text: 'Epidural', value: 'Epidural' },
        { text: 'Blok Saraf Tepi', value: 'Blok Saraf Tepi' }
      ],
      col: 6
    },
    p2: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Masalah Penyulit'
    }
  }
}
