<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">
      Asesmen Pre Induksi Dan Pemantauan Anestesi / Sedasi
    </h3>
    <v-row justify="center">
      <v-col cols="12">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3 mb-3" />
        <h6>ANESTESI</h6>
        <small>Pre Induksi</small>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form0"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form0[i] = e)"
              :value="data.form0[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <small>Induksi</small>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form00"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form00[i] = e)"
              :value="data.form00[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <h6>PEMBERIAN OBAT SELAMA ANESTESI</h6>
        <div style="overflow-x: scroll">
          <table class="table" border="1">
            <thead>
              <tr v-for="item in 3" :key="'a' + item">
                <th colspan="11">
                  <v-text-field
                    v-model="data.form1['p' + item]"
                    :label="item + '.'"
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
                <th colspan="20">
                  <v-text-field
                    v-model="data.form1['p' + (item + 3)]"
                    :label="item + 3 + '.'"
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
                <th colspan="20">
                  <v-text-field
                    v-model="data.form1['p' + (item + 6)]"
                    :label="item + 6 + '.'"
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
              </tr>
              <tr v-for="item2 in 3" :key="'c' + item2">
                <th colspan="3">
                  {{ item2 === 1 ? "Jam" : item2 === 2 ? "Obat" : "Cairan" }}
                </th>
                <th v-for="item in 16" :key="'b' + item" colspan="3">
                  <v-text-field
                    v-model="data.form2[item2]['p' + item]"
                    label=" "
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
              </tr>
              <tr class="text-center" v-for="item in 20" :key="'d' + item">
                <th
                  style="text-align: left !important; min-width: 200px"
                  v-if="[1, 3, 5, 7, 9, 11, 13, 15, 17].includes(item)"
                >
                  {{ text1[item][0] }}
                </th>
                <th v-else>{{ text1[item][0] }}</th>
                <th>{{ text1[item][1] }}</th>
                <th>{{ text1[item][2] }}</th>
                <th
                  v-for="item2 in 48"
                  :key="'dd' + item2"
                  v-bind:style="{
                    backgroundColor: data.form3[item]['p' + item2]
                      ? 'green'
                      : 'white',
                  }"
                >
                  <v-checkbox
                    v-model="data.form3[item]['p' + item2]"
                    label=" "
                    :value="data.form3[item]['p' + item2]"
                    :class="`mt-0 pt-0 hidecheckbox`"
                    hide-details="auto"
                  />
                </th>
              </tr>
              <tr>
                <th colspan="3">Sp O2</th>
                <th v-for="item in 16" :key="'b' + item" colspan="3">
                  <v-text-field
                    v-model="data.form4['p' + item]"
                    label=" "
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
              </tr>
              <tr>
                <th colspan="3">OBAT INHALASI</th>
                <th colspan="3" class="text-center">
                  <v-radio-group
                    v-model="data.form5"
                    class="pt-0 pb-0 mt-0 mb-0"
                    hide-details="auto"
                  >
                    <div><v-radio label="Enflurane" value="Enflurane" /></div>
                  </v-radio-group>
                </th>
                <th colspan="3" class="text-center">
                  <v-radio-group
                    v-model="data.form5"
                    class="pt-0 pb-0 mt-0 mb-0"
                    hide-details="auto"
                  >
                    <div><v-radio label="Isoflurane" value="Isoflurane" /></div>
                  </v-radio-group>
                </th>
                <th colspan="3" class="text-center">
                  <v-radio-group
                    v-model="data.form5"
                    class="pt-0 pb-0 mt-0 mb-0"
                    hide-details="auto"
                  >
                    <div><v-radio label="Desflurene" value="Desflurene" /></div>
                  </v-radio-group>
                </th>
                <th colspan="3" class="text-center">
                  <v-radio-group
                    v-model="data.form5"
                    class="pt-0 pb-0 mt-0 mb-0"
                    hide-details="auto"
                  >
                    <div>
                      <v-radio label="Sevoflurane" value="Sevoflurane" />
                    </div>
                  </v-radio-group>
                </th>
                <th colspan="6" class="text-center">
                  <v-row>
                    <v-radio-group
                      v-model="data.form5"
                      class="pt-0 pb-0 mt-0 mb-0"
                      hide-details="auto"
                    >
                      <div><v-radio label="Lainnya" :value="null" /></div>
                    </v-radio-group>
                    <v-text-field
                      v-if="data.form5 === null"
                      v-model="data.form5"
                      label=" "
                      dense
                      outlined
                      hide-details="auto"
                    />
                  </v-row>
                </th>
              </tr>
            </thead>
          </table>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form6"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form6[i] = e)"
                :value="data.form6[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form7"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form7[i] = e)"
                :value="data.form7[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0 mb-0">
      <v-col cols="6">
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form8"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form8[i] = e)"
              :value="data.form8[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form9"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form9[i] = e)"
              :value="data.form9[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form10"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form10[i] = e)"
              :value="data.form10[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <v-col cols="6"> Total : {{ totalcairanmasuk }} </v-col>
          <v-col cols="6"> Total : {{ totalcairankeluar }} </v-col>
          <v-col
            v-for="(v, i) in master.form11"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form11[i] = e)"
              :value="data.form11[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3" />
    <v-row class="mt-0 mb-0">
      <div>
        <h5>SEDASI</h5>
        <h6>PEMBERIAN OBAT SELAMA ANESTESI</h6>
      </div>
      <div style="overflow-x: scroll">
        <table class="table" border="1">
          <thead>
            <tr v-for="item in 2" :key="'a' + item">
              <th colspan="11">
                <v-text-field
                  v-model="data.form12['p' + item]"
                  :label="item + '.'"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
              <th colspan="20">
                <v-text-field
                  v-model="data.form12['p' + (item + 2)]"
                  :label="item + 2 + '.'"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
              <th colspan="20">
                <v-text-field
                  v-model="data.form12['p' + (item + 4)]"
                  :label="item + 4 + '.'"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr v-for="item2 in 3" :key="'c' + item2">
              <th colspan="3">
                {{ item2 === 1 ? "Jam" : item2 === 2 ? "Obat" : "Cairan" }}
              </th>
              <th v-for="item in 16" :key="'b' + item" colspan="3">
                <v-text-field
                  v-model="data.form13[item2]['p' + item]"
                  label=" "
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr class="text-center" v-for="item in 20" :key="'d' + item">
              <th
                style="text-align: left !important; min-width: 200px"
                v-if="[1, 3, 5, 7, 9, 11, 13, 15, 17].includes(item)"
              >
                {{ text1[item][0] }}
              </th>
              <th v-else>{{ text1[item][0] }}</th>
              <th>{{ text1[item][1] }}</th>
              <th>{{ text1[item][2] }}</th>
              <th
                v-for="item2 in 48"
                :key="'dd' + item2"
                v-bind:style="{
                  backgroundColor: data.form14[item]['p' + item2]
                    ? 'green'
                    : 'white',
                }"
              >
                <v-checkbox
                  v-model="data.form14[item]['p' + item2]"
                  label=" "
                  :value="data.form14[item]['p' + item2]"
                  :class="`mt-0 pt-0 hidecheckbox`"
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th colspan="3">Sp O2</th>
              <th v-for="item in 16" :key="'b' + item" colspan="3">
                <v-text-field
                  v-model="data.form15['p' + item]"
                  label=" "
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </v-row>
    <v-divider class="mt-3 mb-3" />
    <v-row class="mt-0 mb-0">
      <v-col
        v-for="(v, i) in master.form16"
        :md="v.col ? v.col : 12"
        :lg="v.col ? v.col : 12"
        :sm="v.col ? v.col : 12"
        cols="12"
        class="mt-0 mb-0 pt-0 pb-3"
        :key="i"
      >
        <smart-widget
          :comp="v.widget"
          :sync-value="(e) => (data.form16[i] = e)"
          :value="data.form16[i]"
          vclass="c-text-field"
          :data="v.data"
          :property="v.property"
          :label="v.label ? v.label : i.replaceAll('_', ' ')"
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/asesmen-pre-induksi-dan-pemantauan-anestesi-sedasi'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      text1: {
        1: ['Petunjuk', 'Suhu', 'Tensi'],
        2: ['', '', '180'],
        3: ['Tensi', '', ''],
        4: ['v', '', '160'],
        5: ['Nadi', '40', ''],
        6: ['・', '', '140'],
        7: ['RR', '39', ''],
        8: ['◦', '', '120'],
        9: ['Suhu', '38', ''],
        10: ['tulis angka', '', '100'],
        11: ['Mulai anestesi', '37', ''],
        12: ['◦->', '', '80'],
        13: ['Mulai Operasi', '36', ''],
        14: ['X', '', '60'],
        15: ['Selesai Operasi', '35', ''],
        16: ['X', '', '40'],
        17: ['Selesai anestesi', '34', ''],
        18: ['<-◦', '', '20'],
        19: ['', '', ''],
        20: ['', '', '0']
      },
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form0: {},
        form00: {},
        form1: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: ''
        },
        form2: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: ''
          }
        },
        form3: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          6: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          7: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          8: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          9: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          10: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          11: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          12: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          13: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          14: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          15: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          16: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          17: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          18: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          19: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          20: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          }
        },
        form4: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: ''
        },
        form5: '',
        form6: {},
        form7: {},
        form8: {},
        form9: {},
        form10: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: ''
        },
        form11: {},
        form12: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: ''
        },
        form13: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: ''
          }
        },
        form14: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          6: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          7: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          8: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          9: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          10: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          11: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          12: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          13: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          14: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          15: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          16: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          17: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          18: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          19: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          },
          20: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: ''
          }
        },
        form15: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: ''
        },
        form16: {}
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
    totalcairanmasuk: function () {
      var hmm = this
      var x = this.data.form10
      const sum =
        parseInt(x.p1 ? x.p1 : 0) +
        parseInt(x.p3 ? x.p3 : 0) +
        parseInt(x.p5 ? x.p5 : 0) +
        parseInt(x.p7 ? x.p7 : 0)
      if (isNaN(sum)) {
        hmm.data.form11.p1 = '0'
        return 0
      } else {
        hmm.data.form11.p1 = sum.toString()
        return sum
      }
    },
    totalcairankeluar: function () {
      var hmm = this
      var x = this.data.form10
      const sum =
        parseInt(x.p2 ? x.p2 : 0) +
        parseInt(x.p4 ? x.p4 : 0) +
        parseInt(x.p6 ? x.p6 : 0) +
        parseInt(x.p8 ? x.p8 : 0)
      if (isNaN(sum)) {
        hmm.data.form11.p2 = '0'
        return 0
      } else {
        hmm.data.form11.p2 = sum.toString()
        return sum
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>
table tr td {
  text-align: center;
  width: 100%;
}
.hidecheckbox {
  transform: scale(1.5);
  opacity: 0;
}
.wrap {
  width: 100%;
  height: 690px;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
